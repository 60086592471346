
import React, { useState, useEffect } from 'react';
import { Divider, Typography, Row, Col, Card, Button, Modal, Input   } from 'antd';
import { Outlet, Link } from "react-router-dom";
import apiClient from '../services/axios';

const { Title, Paragraph, Text } = Typography;
const { Meta } = Card;

function Home() {

    const comingSoonMeta = {
        title: 'Coming Soon!',
        description: 'Click here to register for early access',
    };

    const compareMeta = {
        title: 'Compare',
        description: 'Benchmark your business to find new opportunity for efficiency and growth',
    };

    const forecastMeta = {
        title: 'Forecast',
        description: 'Forecast business performance over a 12 month period with different senarios',
    };

    const [compareSwitch, setCompareSwitch] = useState(false)
    const [forecastSwitch, setForecastSwitch] = useState(false)

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);

        let options = {
            method: 'post',
            url: '/public/addsubscriber',
            data: {email: emailAddress},
            validateStatus: () => true,
          }
      
        apiClient(options)
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                console.log('Saved!');
            } else {
                console.log('Error: ' + JSON.stringify(response.data));
            }
        })
        .catch(err => {
            console.log(err)
        })
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [emailAddress, setEmailAddress] = useState('');

return (
<>
    <video playsInline autoPlay muted loop id="background-video">
    <source src="./images/city-night-roads-small.mp4" type="video/mp4" />
    </video>
    <div id="background-filter"></div>
    <div id="home-page-content">
        <Title className='white-title'>
            Business Benchmark Tools
        </Title>
        <Row gutter={25}>
        <Col xs={24} xl={8}>
            <Link to={`browse`}>
                <Card
                    hoverable
                    style={{

                    }}
                    cover={''}
                >
                    <Meta style={{textAlign: 'center'}} title="Explore" description="Explore the range and average of benchmarks for different businesses" />
                </Card>
            </Link>
        </Col>
        <Col xs={24} xl={8}>
            <Card
                onClick={() => {if(compareSwitch) { showModal() } setCompareSwitch(!compareSwitch) }}
                hoverable
                style={{

                }}
                cover={''}
            >
                <Meta style={{textAlign: 'center'}} title={compareSwitch?comingSoonMeta.title:compareMeta.title} description={compareSwitch?comingSoonMeta.description:compareMeta.description} />
            </Card>
            
        </Col>
        <Col xs={24} xl={8}>
            <Card
                onClick={() => {if(forecastSwitch) { showModal() } setForecastSwitch(!forecastSwitch)}}
                hoverable
                style={{

                }}
                cover={''}
            >
                <Meta style={{textAlign: 'center'}} title={forecastSwitch?comingSoonMeta.title:forecastMeta.title} description={forecastSwitch?comingSoonMeta.description:forecastMeta.description} />
            </Card>
        </Col>
        <Modal title="Register for Early Access" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Input placeholder="Email address" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
        </Modal>
        </Row>  
        <Row style={{marginTop: '40px' }}>
            <Col>
                <Title level={3} className='white-title'>How is this useful for my business?</Title>           
                <Paragraph className='white-text'>Good question! Perhaps the best way to think about business benchmarking is it will help you to ask important questions about your business and how you do business.</Paragraph>        
                <Title level={3} className='white-title'>Why is this important?</Title>           
                <Paragraph className='white-text'>When we are in the daily grind of running our business, it can be hard to see what is going on, and even when we have a reasonable idea, what things should we be looking to improve. Benchmarking is a powerful tool assisting in discussion and planning for the future direction of your business. </Paragraph>        
                <Title level={3} className='white-title'>Where do I start?</Title>           
                <Paragraph className='white-text'>Most important thing to remember when looking at the industry benchmark data, the average and range of a value represents the consolidation of thousands of businesses across an industry who are operating under different contexts with different goals. 
      <ul>
        <li style={{marginTop: '15px'}}><strong>Does my business currently operate within the industry standard ranges?</strong>
<div>This does not necessarily predict business profitability, but does give insight into the average business model and how the average business is operating in a way to find their profit margin.</div></li>
<li style={{marginTop: '15px'}}><strong>If you see significant differences between your business and the industry range</strong>
<div>Again, this does not indicate profitability, but you should consider how or why your business is significantly different from the industry. Sometimes this could be a competitive advantage, but you will want to understand why this difference exists and if there are any potential implications for the future of the business.</div></li>
</ul>
</Paragraph>        
            
            
            
            </Col>
        </Row>      
    </div>
</>
);
}

export default Home;