import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
//import App from './App';
import ThemeLayout from "./pages/ThemeLayout";
import Home from './pages/Home';
import Browse from './pages/Browse';
import NoPage from "./pages/Nopage";

import reportWebVitals from './reportWebVitals';

/*/ Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDNtALnHUEr9j0PWHBa6XTqWs6ICdb88ek",
  authDomain: "growthlogic-benchmarking.firebaseapp.com",
  projectId: "growthlogic-benchmarking",
  storageBucket: "growthlogic-benchmarking.appspot.com",
  messagingSenderId: "143062217927",
  appId: "1:143062217927:web:911330251ff3189f3ebae2",
  measurementId: "G-87VFW557B5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
*/

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ThemeLayout />}>
          <Route index element={<Home />} />
          <Route path="browse" element={<Browse />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
