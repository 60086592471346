import React, { useRef, useEffect } from 'react'

const BulletChart = props => {
  
  const canvasRef = useRef(null)
  
  const draw = (ctx) => {
    const canvas = ctx.canvas;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    var sidemargin = 20;

    var barwidth = canvas.width - (sidemargin*2);
    var widthstep = barwidth/100;

    // Grey Bar
    const greypattern = ctx.createPattern(patternCanvas("#f0f0f0", "#fafafa"), 'repeat');
    ctx.fillStyle = greypattern;
    ctx.fillRect(sidemargin, 35, barwidth, 30);


    const bluepattern = ctx.createPattern(patternCanvas("#69c0ff", "#40a9ff"), 'repeat');

    // Key
    var keyx = 0;
    if(props.val1 < 50) {
        if(props.val2)
            keyx = canvas.width - 300;
        else
            keyx = canvas.width - 205;
    }  

    ctx.fillStyle = bluepattern;
    ctx.fillRect(keyx+20, 10, 10, 10);  
    ctx.fillStyle = "#434343";
    ctx.font = "bold 10px arial";
    ctx.textAlign = 'left';
    ctx.fillText("Benchmark Range", keyx+35, 18, 200);

    ctx.fillStyle = "#002766";
    ctx.fillRect(keyx+130, 10, 10, 10);
    ctx.fillStyle = "#434343";
    ctx.font = "bold 10px arial";
    ctx.textAlign = 'left';
    ctx.fillText("Average", keyx+145, 18, 200);

    if(props.val2) {
        ctx.fillStyle = "#cf1322";
        ctx.fillRect(keyx+190, 10, 10, 10);
        ctx.fillStyle = "#434343";
        ctx.font = "bold 10px arial";
        ctx.textAlign = 'left';
        ctx.fillText("Your Business", keyx+205, 18, 200);
    }

    // Range Bar
    if(props.min && props.max) {
        var rangex1 = sidemargin + (props.min*widthstep)-widthstep;
        var rangex2 = sidemargin + (props.max*widthstep);
        var rangexwidth = rangex2-rangex1;

        
        ctx.fillStyle = bluepattern;
        ctx.fillRect(rangex1, 35, rangexwidth, 30);   
        
        // Left Val1 Line
        if(props.val1) {
            var val1x = sidemargin + (props.val1*widthstep)-(widthstep*0.5);
            ctx.fillStyle = "#002766";
            let Val1arrow = new Path2D();
            Val1arrow.moveTo(val1x-6, 30);
            Val1arrow.lineTo(val1x+6, 30);
            Val1arrow.lineTo(val1x, 42);
            Val1arrow.closePath();
            ctx.fill(Val1arrow);
            
            //let Val1bubble = new Path2D();        
            var val1bubblex = val1x-20;
            //Val1bubble.roundRect(val1bubblex, 0, 40, 30, [4]);
            ctx.fillStyle = "#002766";
            ctx.fillRect(val1bubblex, 0, 40, 30);

            ctx.fillStyle = "white";
            ctx.font = "14px arial";
            ctx.textAlign = 'center';
            ctx.fillText(props.val1+"%", val1x, 20, 200);
        }

        // Right Val2 Line    
        /*if(props.val2) {
            var val2x = sidemargin + (props.val2*widthstep)-(widthstep*0.5);       
            ctx.fillStyle = "#cf1322";
            let Val2arrow = new Path2D();
            Val2arrow.moveTo(val2x-6, 70);
            Val2arrow.lineTo(val2x+6, 70);
            Val2arrow.lineTo(val2x, 58);
            Val2arrow.closePath();
            ctx.fill(Val2arrow);
            ctx.fillStyle = "#cf1322";
            var val2bubblex = val2x-20;
            //ctx.beginPath();
            ctx.roundRect(val2bubblex, canvas.height-30, 40, 30, [4]);
            ctx.fill();
            
            ctx.fillStyle = "white";
            ctx.font = "14px arial";
            ctx.textAlign = 'center';
            ctx.fillText(props.val2+"%", val2x, canvas.height-10, 200);
        }*/

        ctx.fillStyle = "black";
        ctx.font = "bold 14px arial";
        ctx.textAlign = 'right';
        ctx.fillText(props.min+"%", rangex1 - 3, (canvas.height / 2)+7, 200); //
    
        ctx.fillStyle = "black";
        ctx.font = "bold 14px arial";
        ctx.textAlign = 'left';
        ctx.fillText(props.max+"%", rangex2 + 3, (canvas.height / 2)+7, 200); //
    }

    //requestAnimationFrame(() => draw(ctx));
  }

  const patternCanvas = (color1, color2) => {
    const patternCanvas = document.createElement('canvas');
    const patternContext = patternCanvas.getContext('2d');
    patternCanvas.width = 300;
    patternCanvas.height = 300;
    var numberOfStripes = 100;		
    for (var i=0;i<numberOfStripes*2;i++){
      var thickness = 300 / numberOfStripes;
      patternContext.beginPath();
      patternContext.strokeStyle = i % 2?color1:color2;
      patternContext.lineWidth =thickness;
      patternContext.lineCap = 'round';
      
      patternContext.moveTo(i*thickness + thickness/2 - 300,0);
      patternContext.lineTo(0 + i*thickness+thickness/2,300);
      patternContext.stroke();
    }
    return patternCanvas;
  }

  useEffect(() => {
    
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    
    requestAnimationFrame(() => draw(context));

    const handleResize = e => {
        canvasRef.current.width = canvasRef.current.clientWidth<200?200:canvasRef.current.clientWidth;
        canvasRef.current.height = canvasRef.current.clientHeight;

        requestAnimationFrame(() => draw(context));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [draw])
  
  return <canvas ref={canvasRef} {...props}/>
}

export default BulletChart