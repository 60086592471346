import React, { useState, useEffect } from 'react';
import { Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-dragdata'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const season_chart_options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Line Chart',
      },
      dragData: true,
      onDragStart: function (e) {
        //console.log(e)
      },
      onDrag: function (e, datasetIndex, index, value) {
        //console.log(datasetIndex, index, value)
      },
      onDragEnd: function (e, datasetIndex, index, value) {
        console.log(datasetIndex, index, value)
      }
    },
    scales: {
        y: {
          max: 100,
          min: 0
        }
    }
  };
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: [50,50,50,50,50,50,50,50,50,50,50,50],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        pointRadius: 5,
        pointHoverRadius: 10,
        pointHitRadius: 25
      },
    ],
  };

  const Seasons = () => {
    return (<Line options={season_chart_options} data={data} />)
  }

  export default Seasons