import _ from "lodash";
import React, { useState, useEffect } from 'react';
import { Button, Radio, InputNumber, Divider, Typography, Select, Row, Col, Layout, Menu, Card, Slider, Segmented  } from 'antd';
import Seasons from '../components/seasons'
import BulletChart from '../components/bulletchart'

//import './App.css';

const { Header, Content, Footer } = Layout;
const { Option } = Select;
const { Title, Paragraph } = Typography;

function Browse() {

  const industriesList = {
    "Accommodation and food": "benchmark_data_1.json",
    "Building and construction trade services": "benchmark_data_2.json",
    "Education, training, recreation and support services": "benchmark_data_3.json",
    "Health care and personal services": "benchmark_data_4.json",
    "Manufacturing": "benchmark_data_5.json",
    "Other services": "benchmark_data_6.json",
    "Professional, scientific and technical services": "benchmark_data_7.json",
    "Retail trade": "benchmark_data_8.json",
    "Transport, postal and warehousing": "benchmark_data_9.json",
  };

  const [benchmarkDetails, setBenchmarkDetails] = useState({
    industry: '',
    businesstype: '',
    rangeselect: '',
    ranges: [],
    data: {}
  });

  const [industryData, setIndustryData] = useState({ Benchmarks: [] })

  useEffect(() => {
  }, [])
    
  const updateBenchmark = (key, value) => {
    switch(key) {
      case 'industry':
        if(value !== '' && benchmarkDetails.industry !== value) {
          setBenchmarkDetails({ industry: value, businesstype: '', rangeselect: '', ranges: [], data: {}});
          fetch('./data/' + industriesList[value]).then(response => {
            return response.json();
          }).then(data => {
            // Work with JSON data here
            setIndustryData(data);
          }).catch(err => {
            // Do something for an error here
            console.log("Error Reading data " + err);
          });         
        }
        break;
      case 'businesstype':
        if(value !== '' && benchmarkDetails.businesstype !== value) {
          let vals = {};
          industryData.Benchmarks.forEach((biz, index) => {
            if(value===biz.Business) {
              vals = { businesstype: value, rangeselect: '', ranges: biz.Ranges, data: {}};
            }
          });
          setBenchmarkDetails({...benchmarkDetails, ...vals});
        } 
        break;
      case 'rangeselect':
        if(value !== '' && benchmarkDetails.rangeselect !== value) {
          let vals = {
              "Turnover": {"Min": 0,"Max": 0,"Avg": 0,"Val": 0},
              "COS": {"Min": 0,"Max": 0,"Avg": 0,"Val": 0},
              "Total Expenses": {"Min": 0,"Max": 0,"Avg": 0,"Val": 0},
              "Labour": {"Min": 0,"Max": 0,"Avg": 0,"Val": 0},
              "Rent": {"Min": 0,"Max": 0,"Avg": 0,"Val": 0}, 
              "Motor Vehicle": {"Min": 0,"Max": 0,"Avg": 0,"Val": 0}                                   
          };
          benchmarkDetails.ranges.forEach((range, index) => {
            if(value===range.Turnover.Max) {
              vals = {...vals, ..._.cloneDeep(range)};
              for (var key in vals) {
                if(vals[key].Min !== 0 && vals[key].Max !== 0) {
                  if(key === "Turnover") {
                    vals[key].Max = (vals[key].Max===1000000000000?Math.ceil(((vals[key].Min*3)/10000)) * 10000:vals[key].Max)
                    vals[key].Avg = Math.ceil(((vals[key].Max-vals[key].Min)/2+vals[key].Min)/1000)*1000;                   
                  }
                  vals[key].Val = vals[key].Avg;
                }
              }
            }
          });
          setBenchmarkDetails({...benchmarkDetails, rangeselect: value, data: vals})
        } 
        break;       
      default:
        
      break;
    }
  }

  const updateBenchmarkData = (opt, val) => {  
      let data = {...benchmarkDetails.data};
      if(opt === 'Turnover') {
        let min = data.Turnover.Min;
        let max = data.Turnover.Max;
        data.Turnover.Val = min+Math.ceil((((max-min))*(val/100))/1000)*1000;
        console.log(data.Turnover.Val);
      } else {
        data[opt].Val = (val/100);
      }
      setBenchmarkDetails({...benchmarkDetails, data: data});
  }

  const setSetSalesRevenue = (value) => {
    let data = {...benchmarkDetails.data};
    if(isNumeric(value)) {

    }
  }

  function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  const createIndustriesOptions = () => {
    let options = [];
    for (var key in industriesList) {
      options.push(<Option value={key}>{key}</Option>);
    }
    return options;
  } 

  const createIndustryOptions = () => {
    let options = [];
    industryData.Benchmarks.forEach((biz, index) => {
      options.push(<Option value={biz.Business}>{biz.Business}</Option>);
    });
    return options;
  } 

  const createBusinessSizeOptions = () => {

    if(benchmarkDetails.ranges.length > 3 || benchmarkDetails.ranges.length === 0) {
      //return [<Radio.Button value="" disabled>Select a Business Type</Radio.Button>];
      return [{
        label: (
          <div style={{ padding: 4 }}>
            <div style={{fontSize: 16, lineHeight: 1}}>Select a Business Type</div>
            <div style={{fontSize: 11}}></div>
          </div>
        ),
        value: '',
        disabled: true
      }]
    }
    let options = [];
    let size_labels = ["Small", "Medium", "Large"];

    if(benchmarkDetails.ranges.length === 1) {
      size_labels = ["Standard"];
    } 
    if(benchmarkDetails.ranges.length === 2) {
      size_labels = ["Small", "Medium/Large"];
    }     

    benchmarkDetails.ranges.forEach((val, index) => {
      options.push({
        label: (
          <div style={{ padding: 4 }}>
            <div style={{fontSize: 16, lineHeight: 1}}>{size_labels[index]}</div>
            <div style={{fontSize: 11}}>{new Intl.NumberFormat().format(val.Turnover.Min)}{(val.Turnover.Max===1000000000000?' +':' - '+new Intl.NumberFormat().format(val.Turnover.Max))}</div>
          </div>
        ),
        value: val.Turnover.Max,
      });
    });
    return options;
  }

  const getValue = (key, val) => {
    if(benchmarkDetails.data[key]) {
      if(benchmarkDetails.data[key][val]) {
        if( val === 'Val') return null
        return Intl.NumberFormat().format(benchmarkDetails.data[key][val]*100);
      }
    } else if ( benchmarkDetails.data['Total Expenses'] && key === 'Expenses') {
      
      var expensesmin = Intl.NumberFormat().format(benchmarkDetails.data['Total Expenses'].Min*100);
      var expensesmax = Intl.NumberFormat().format(benchmarkDetails.data['Total Expenses'].Max*100);
      var expensesavg = Intl.NumberFormat().format(benchmarkDetails.data['Total Expenses'].Avg*100);

      expensesmin = expensesmin - Intl.NumberFormat().format(benchmarkDetails.data['COS'].Min*100);
      expensesmin = expensesmin - Intl.NumberFormat().format(benchmarkDetails.data['Labour'].Min*100);
      expensesmin = expensesmin - Intl.NumberFormat().format(benchmarkDetails.data['Rent'].Min*100);

      expensesmax = expensesmax - Intl.NumberFormat().format(benchmarkDetails.data['COS'].Max*100);
      expensesmax = expensesmax - Intl.NumberFormat().format(benchmarkDetails.data['Labour'].Max*100);
      expensesmax = expensesmax - Intl.NumberFormat().format(benchmarkDetails.data['Rent'].Max*100);

      expensesavg = expensesavg - Intl.NumberFormat().format(benchmarkDetails.data['COS'].Avg*100);
      expensesavg = expensesavg - Intl.NumberFormat().format(benchmarkDetails.data['Labour'].Avg*100);
      expensesavg = expensesavg - Intl.NumberFormat().format(benchmarkDetails.data['Rent'].Avg*100);

      switch(val) {
        case 'Min':
          return expensesmin;
        case 'Max':
          return expensesmax;
        case 'Avg':
          return expensesavg;
        default:
          return null;  
      }
    } else if ( benchmarkDetails.data['Total Expenses'] && key === 'Profit') {
      var profitmin = 100-Intl.NumberFormat().format(benchmarkDetails.data['Total Expenses'].Max*100);
      var profitmax = 100-Intl.NumberFormat().format(benchmarkDetails.data['Total Expenses'].Min*100);
      var profitavg = 100-Intl.NumberFormat().format(benchmarkDetails.data['Total Expenses'].Avg*100);     
      
      switch(val) {
        case 'Min':
          return profitmin;
        case 'Max':
          return profitmax;
        case 'Avg':
          return profitavg;
        default:
          return null;  
      }
    }
    return null;
  }

  const getPnLValue = (item) => {

    if(benchmarkDetails.data['Turnover']) {
      if(benchmarkDetails.data['Turnover']['Val']) {
        var turnover = benchmarkDetails.data.Turnover.Val;
        var cos = 0;
        var labour = 0;
        var rent = 0;
        var motor = 0;
        var totalexpenses = 0;
        var general_expenses = 0;

        if(benchmarkDetails.data['COS']) {
          if(benchmarkDetails.data['COS']['Val']) {
            cos = (turnover*benchmarkDetails.data.COS.Val);
          }
        }

        if(benchmarkDetails.data['Total Expenses']) {
          if(benchmarkDetails.data['Total Expenses']['Val']) {
            totalexpenses = (turnover*benchmarkDetails.data['Total Expenses'].Val);
          }
        }

        if(benchmarkDetails.data['Motor Vehicle']) {
          if(benchmarkDetails.data['Motor Vehicle']['Val']) {
            if((benchmarkDetails.data['Motor Vehicle'].Max*100) > 4) {
              motor = (turnover*benchmarkDetails.data['Motor Vehicle'].Val);
            }
          }
        }

        if(benchmarkDetails.data['Rent']) {
          if(benchmarkDetails.data['Rent']['Val']) {
            rent = (turnover*benchmarkDetails.data.Rent.Val);
          }
        }

        if(benchmarkDetails.data['Labour']) {
          if(benchmarkDetails.data['Labour']['Val']) {
            labour = (turnover*benchmarkDetails.data.Labour.Val);
          }
        }

        if(totalexpenses-cos-labour-rent-motor < 0) {
          totalexpenses = cos + labour + rent + motor;
        } else {
          general_expenses = totalexpenses-cos-labour-rent-motor;
        }

        switch(item) {
          case 'Sales':
            return "$"+Intl.NumberFormat().format(turnover);
          case 'Cost of Sales':
            return "$"+Intl.NumberFormat().format(cos);
          case 'Gross Profit':
            return "$"+Intl.NumberFormat().format(turnover-cos);
          case 'Labour':
            return "$"+Intl.NumberFormat().format(labour);
          case 'Rent':
            return "$"+Intl.NumberFormat().format(rent); 
          case 'Motor Vehicle':
            return "$"+Intl.NumberFormat().format(motor);
          case 'General Expenses':
            return "$"+Intl.NumberFormat().format(general_expenses);   
          case 'Total Expenses':
            return "$"+Intl.NumberFormat().format(totalexpenses-cos);      
          case 'Net Profit':
            return "$"+Intl.NumberFormat().format(turnover-totalexpenses);                     
          default:
            return "-";  
        }   
      }
    }

    return "-"; 
  }

  const sliderOptions = (opt, key) => {
    if(benchmarkDetails.data.Turnover) {
      let min = benchmarkDetails.data[key].Min;
      let max = benchmarkDetails.data[key].Max;
      let avg = benchmarkDetails.data[key].Avg;
      let val = benchmarkDetails.data[key].Val;
      switch(opt) {
        case 'value':
          if(key==='Turnover') {
            return Math.round((val-min)/((max-min)/100));
          }
          return (val*100);
          break;
        case 'visible':
          if(benchmarkDetails.data[key]) {
            return true;
          }
          return false;
          break;
        case 'marks':
          if(key==='Turnover') {
            return {
              0: new Intl.NumberFormat().format(min), 
              [Math.round(avg/(min+max)*100)]: new Intl.NumberFormat().format(avg), 
              100: new Intl.NumberFormat().format(max)
            };
          } else {
            return {
              0: '0%', 
              [min*100]: (min*100)+'%', 
              [max*100]: (max*100)+'%',
              100: '100%'         
            };
          }
          break;                  
        default:
      }
    }
    //{0: '0°C', 26: '26°C', 37: '37°C', 100: { style: { color: '#f50', }, label: <strong>100°C</strong>}}
    return false;
  }

  return (
    <div className="layout-content-page">
        <Row style={{marginBottom: '8n'}}>
          <Col flex="auto">
            <Card size="large">
              <Title level={1}>Explore Business Benchmarks</Title>
              <Paragraph>Explore a range of business benchmarks from different industries and understand expected expenses and potential profitability.</Paragraph>
              <Paragraph>Remember that benchmark data takes a large group of businesses operating under different circumstances and does not necessarily represent the best way to operate your business.</Paragraph>
              <Paragraph>If you would like to understand more about your business performance, contact us at team@growthlogic.com.au</Paragraph>
              <Title level={5}>Select an Industry</Title>
              <Select defaultValue={""} style={{ width: '100%', marginBottom: '20px' }} value={benchmarkDetails.industry} onChange={e => {updateBenchmark('industry', e)}}>
                <Option value="" disabled selected="selected">
                  -- Select industry --
                </Option>
                {createIndustriesOptions()} 
              </Select>
              <Title level={5}>Select a Business Type</Title>
              <Select defaultValue={""} style={{ width: '100%', marginBottom: '20px' }} value={benchmarkDetails.businesstype} onChange={e => updateBenchmark('businesstype', e)}>
                <Option value="" disabled selected="selected">
                  -- Select business --
                </Option>
                {createIndustryOptions()} 
              </Select>
              <Title level={5}>Business Size <small style={{fontWeight: 'normal'}}>(by annual turnover)</small></Title>
              <Segmented block value={benchmarkDetails.rangeselect} onChange={e => updateBenchmark('rangeselect', e)} options={createBusinessSizeOptions()} />
            </Card>            
          </Col>
        </Row>   
        <Row hidden={getValue('COS','Avg')===null}>
          <Col flex="auto">
            <Card size="large">
              <Row>
                <Col span={24}><Title level={4} style={{fontWeight: 'bold'}}>Cost of Sales (%)</Title></Col>
              </Row>              
              <Row>
                <Col span={24}><BulletChart id="cos-bt" style={{width: '100%', height: '100px'}} min={getValue('COS','Min')} max={getValue('COS','Max')} val1={getValue('COS','Avg')} val2={getValue('COS','Val')} /></Col>
              </Row>              
            </Card>
          </Col>
        </Row>
        <Row hidden={getValue('Labour','Avg')===null}>
          <Col flex="auto">
            <Card size="large">
              <Row>
                <Col span={24}><Title level={4} style={{fontWeight: 'bold'}}>Labour (%)</Title></Col>
              </Row>
              <Row>
                <Col span={24}><BulletChart id="labour-bt" style={{width: '100%', height: '100px'}} min={getValue('Labour','Min')} max={getValue('Labour','Max')} val1={getValue('Labour','Avg')} val2={getValue('Labour','Val')} /></Col>
              </Row>              
            </Card>
          </Col>
        </Row>
        <Row hidden={getValue('Rent','Avg')===null}>
          <Col flex="auto">
            <Card size="large">
              <Row>
                <Col span={24}><Title level={4} style={{fontWeight: 'bold'}}>Rent (%)</Title></Col>
              </Row>
              <Row>
                <Col span={24}><BulletChart id="rent-bt" style={{width: '100%', height: '100px'}} min={getValue('Rent','Min')} max={getValue('Rent','Max')} val1={getValue('Rent','Avg')} val2={getValue('Rent','Val')} /></Col>
              </Row>              
            </Card>
          </Col>
        </Row>
        <Row hidden={(getValue('Motor Vehicle','Max')!==null)?(getValue('Motor Vehicle','Max') < 5):true}>
          <Col flex="auto">
            <Card size="large">
              <Row>
                <Col span={24}><Title level={4} style={{fontWeight: 'bold'}}>Motor Vehicle (%)</Title></Col>
              </Row>
              <Row>
                <Col span={24}><BulletChart id="motor-bt" style={{width: '100%', height: '100px'}} min={getValue('Motor Vehicle','Min')} max={getValue('Motor Vehicle','Max')} val1={getValue('Motor Vehicle','Avg')} val2={getValue('Motor Vehicle','Val')} /></Col>
              </Row>              
            </Card>
          </Col>
        </Row>        
        <Row hidden={getValue('Total Expenses','Avg')===null}>
          <Col flex="auto">
            <Card size="large">
              <Row>
                <Col span={24}><Title level={4} style={{fontWeight: 'bold'}}>Total Expenses (%)</Title></Col>
              </Row>
              <Row>
                <Col span={24}><BulletChart id="expenses-bt" style={{width: '100%', height: '100px'}} min={getValue('Total Expenses','Min')} max={getValue('Total Expenses','Max')} val1={getValue('Total Expenses','Avg')} val2={getValue('Total Expenses','Val')} /></Col>
              </Row>              
            </Card>
          </Col>
        </Row>
        <Row hidden={getValue('Total Expenses','Avg')===null}>
          <Col flex="auto">
            <Card size="large">
              <Row>
                <Col span={24}><Title level={4} style={{fontWeight: 'bold'}}>Profit Margin (%)</Title></Col>
              </Row> 
              <Row style={{minWidth: '260px'}}>
                <Col span={24} style={{textAlign: 'center'}}>
                  <span class="profitpercent-label ta-r mr-20">{getValue('Profit','Min')}%</span>
                  <Title level={3} class="profitpercent-center">{getValue('Profit','Avg')}%</Title>
                  <span class="profitpercent-label ta-l ml-20">{getValue('Profit','Max')}%</span>
                </Col>
                <Col span={24} style={{textAlign: 'center'}}>
                  <span class="profittext-label ta-r mr-20">Lower Range</span>
                  <span class="profittext-label ta-c">Average</span>
                  <span class="profittext-label ta-l ml-20">Upper Range</span>
                </Col>
              </Row>           
            </Card>
          </Col>
        </Row>        
        <Row hidden={true}>
          <Col flex="auto">
            <Card size="large">
              <Row gutter={[42, 16]} style={{marginBottom: '8n'}}>
                <Col flex="1" style={{paddingRight: '8n'}}><p>Turnover ($)</p></Col>
                <Col flex="4">
                  <Slider marks={sliderOptions('marks', 'Turnover')} included={false} disabled={!sliderOptions('visible', 'Turnover')} value={sliderOptions('value', 'Turnover')} defaultValue={0} onChange={e => updateBenchmarkData('Turnover', e)} />
                </Col>
              </Row>             
              <Row gutter={[42, 16]} style={{marginBottom: '8n'}} disabled={!sliderOptions('visible', 'Total Expenses')}>
                <Col flex="1" style={{paddingRight: '8n'}}><p>Total Expenses (%)</p></Col>
                <Col flex="4">
                 <Slider marks={sliderOptions('marks', 'Total Expenses')} included={false} disabled={!sliderOptions('visible', 'Total Expenses')} value={sliderOptions('value', 'Total Expenses')} defaultValue={0} onChange={e => updateBenchmarkData('Total Expenses', e)} />
                </Col>
              </Row>
              <Row gutter={[42, 16]} style={{marginBottom: '8n'}} disabled={!sliderOptions('visible', 'COS')}>
                <Col flex="1" style={{paddingRight: '8n'}}><p>Cost of Sales (%)</p></Col>
                <Col flex="4">
                 <Slider marks={sliderOptions('marks', 'COS')} included={false} disabled={!sliderOptions('visible', 'COS')} value={sliderOptions('value', 'COS')} defaultValue={0} onChange={e => updateBenchmarkData('COS', e)} />
                </Col>
              </Row>
              <Row gutter={[42, 16]} style={{marginBottom: '8n', display: !sliderOptions('visible', 'Labour')?'none':''}}>
                <Col flex="1" style={{paddingRight: '8n'}}><p>Labour (%)</p></Col>
                <Col flex="4">
                 <Slider marks={sliderOptions('marks', 'Labour')} included={false} disabled={!sliderOptions('visible', 'Labour')} value={sliderOptions('value', 'Labour')} defaultValue={0} onChange={e => updateBenchmarkData('Labour', e)} />
                </Col>
              </Row>
              <Row gutter={[42, 16]} style={{marginBottom: '8n'}} disabled={!sliderOptions('visible', 'Rent')}>
                <Col flex="1" style={{paddingRight: '8n'}}><p>Rent (%)</p></Col>
                <Col flex="4">
                 <Slider marks={sliderOptions('marks', 'Rent')} included={false} disabled={!sliderOptions('visible', 'Rent')} value={sliderOptions('value', 'Rent')} defaultValue={0} onChange={e => updateBenchmarkData('Rent', e)} />
                </Col>
              </Row>
              <Row gutter={[42, 16]} style={{marginBottom: '8n'}} disabled={!sliderOptions('visible', 'Motor Vehicle')}>
                <Col flex="1" style={{paddingRight: '8n'}}><p>Motor Vehicle (%)</p></Col>
                <Col flex="4">
                 <Slider marks={sliderOptions('marks', 'Motor Vehicle')} included={false} disabled={!sliderOptions('visible', 'Motor Vehicle')} value={sliderOptions('value', 'Motor Vehicle')} defaultValue={0} onChange={e => updateBenchmarkData('Motor Vehicle', e)} />
                </Col>
              </Row>
              
            </Card>    
          </Col>
        </Row>    
        <Row hidden={getValue('Total Expenses','Avg')===null}>
          <Col flex="auto">
            <Card size="large" style={{marginTop: '8px'}}>
              <Row>
                <Col span={24}><Title level={4} style={{fontWeight: 'bold'}}>Potential Profit ($)</Title></Col>
              </Row>    
              <Row style={{paddingRight: '8px', paddingLeft: '8px'}}>
                <Col span={12} style={{textAlign: 'left'}}><Title level={5} style={{fontWeight: 'bold', marginBottom: 0}}>Sales</Title></Col>
                <Col span={12} style={{textAlign: 'right'}}></Col>
              </Row>           
              <Row style={{paddingRight: '8px', paddingLeft: '8px'}}>
                <Col span={12} style={{textAlign: 'left'}}><Title level={5} style={{fontWeight: 'normal', marginBottom: 0}}>Sales</Title></Col>
                <Col span={12} style={{textAlign: 'right'}}>
                  <Paragraph style={{display: 'inline-block', fontSize: '16px', marginBottom: 0}}>{getPnLValue('Sales')}</Paragraph>
                </Col>
              </Row>
              <Divider style={{margin: '8px 0'}} />
              <Row style={{paddingRight: '8px', paddingLeft: '8px'}} hidden={getValue('COS','Avg')===null}>
                <Col span={12} style={{textAlign: 'left'}}><Title level={5} style={{fontWeight: 'bold', marginBottom: 0}}>Cost of Sales</Title></Col>
                <Col span={12} style={{textAlign: 'right'}}></Col>
              </Row>
              <Row style={{paddingRight: '8px', paddingLeft: '8px'}} hidden={getValue('COS','Avg')===null}>
                <Col span={12} style={{textAlign: 'left'}}><Title level={5} style={{fontWeight: 'normal', marginBottom: 0}}>Cost of Sales</Title></Col>
                <Col span={12} style={{textAlign: 'right'}}>
                  <Paragraph style={{display: 'inline-block', fontSize: '16px', marginBottom: 0}}>{getPnLValue('Cost of Sales')}</Paragraph>
                </Col>
              </Row>
              <Divider style={{margin: '8px 0'}} hidden={getValue('COS','Avg')===null} />
              <Row style={{paddingRight: '8px', paddingLeft: '8px'}}>
                <Col span={12} style={{textAlign: 'left'}}><Title level={5} style={{fontWeight: 'bold', fontStyle: 'italic', marginBottom: 0}}>Gross Profit</Title></Col>
                <Col span={12} style={{textAlign: 'right'}}>
                  <Paragraph style={{display: 'inline-block', fontSize: '16px', fontWeight: 'bold', marginBottom: 0}}>{getPnLValue('Gross Profit')}</Paragraph>
                </Col>
              </Row>              
              <Divider style={{margin: '8px 0'}} />
              <Row style={{paddingRight: '8px', paddingLeft: '8px'}}>
                <Col span={12} style={{textAlign: 'left'}}><Title level={5} style={{fontWeight: 'bold', marginBottom: 0}}>Expenses</Title></Col>
                <Col span={12} style={{textAlign: 'right'}}></Col>
              </Row>
              <Row style={{paddingRight: '8px', paddingLeft: '8px'}} hidden={getValue('Labour','Avg')===null}>
                <Col span={12} style={{textAlign: 'left'}}><Title level={5} style={{fontWeight: 'normal', marginBottom: 0}}>Labour</Title></Col>
                <Col span={12} style={{textAlign: 'right'}}>
                  <Paragraph style={{display: 'inline-block', fontSize: '16px', marginBottom: 0}}>{getPnLValue('Labour')}</Paragraph>
                </Col>
              </Row>    
              <Row style={{paddingRight: '8px', paddingLeft: '8px'}} hidden={getValue('Rent','Avg')===null}>
                <Col span={12} style={{textAlign: 'left'}}><Title level={5} style={{fontWeight: 'normal', marginBottom: 0}}>Rent</Title></Col>
                <Col span={12} style={{textAlign: 'right'}}>
                  <Paragraph style={{display: 'inline-block', fontSize: '16px', marginBottom: 0}}>{getPnLValue('Rent')}</Paragraph>
                </Col>
              </Row>       
              <Row style={{paddingRight: '8px', paddingLeft: '8px'}} hidden={(getValue('Motor Vehicle','Max')!==null)?(getValue('Motor Vehicle','Max') < 5):true}>
                <Col span={12} style={{textAlign: 'left'}}><Title level={5} style={{fontWeight: 'normal', marginBottom: 0}}>Motor Vehicle</Title></Col>
                <Col span={12} style={{textAlign: 'right'}}>
                  <Paragraph style={{display: 'inline-block', fontSize: '16px', marginBottom: 0}}>{getPnLValue('Motor Vehicle')}</Paragraph>
                </Col>
              </Row>     
              <Row style={{paddingRight: '8px', paddingLeft: '8px'}} hidden={(getPnLValue('General Expenses')==="$0")}>
                <Col span={12} style={{textAlign: 'left'}}><Title level={5} style={{fontWeight: 'normal', marginBottom: 0}}>General Expenses<span style={{display: getValue('Labour','Avg')===null?'block':'none', paddingLeft: '8px', fontSize: '14px'}}>* Including Labour</span></Title></Col>
                <Col span={12} style={{textAlign: 'right'}}>
                  <Paragraph style={{display: 'inline-block', fontSize: '16px', marginBottom: 0}}>{getPnLValue('General Expenses')}</Paragraph>
                </Col>
              </Row> 
              <Divider style={{margin: '8px 0'}} />
              <Row style={{paddingRight: '8px', paddingLeft: '8px'}}>
                <Col span={12} style={{textAlign: 'left'}}><Title level={5} style={{fontWeight: 'bold', fontStyle: 'italic', marginBottom: 0}}>Total Expenses</Title></Col>
                <Col span={12} style={{textAlign: 'right'}}>
                  <Paragraph style={{display: 'inline-block', fontSize: '16px', fontWeight: 'bold', marginBottom: 0}}>{getPnLValue('Total Expenses')}</Paragraph>
                </Col>
              </Row>                
              <Row style={{background: '#f5f5f5', padding: '8px', marginTop: '8px'}}>
                <Col span={12} style={{textAlign: 'left'}}><Title level={4} style={{fontWeight: 'bold', fontStyle: 'italic', marginBottom: 0}}>Net Profit</Title></Col>
                <Col span={12} style={{textAlign: 'right'}}>
                  <Paragraph style={{display: 'inline-block', fontSize: '20px', fontWeight: 'bold', marginBottom: 0}}>{getPnLValue('Net Profit')}</Paragraph>
                </Col>
              </Row>              
            </Card>
            <Card size="large" style={{marginTop: '8px'}}>
              <Title level={3}>Looking for deeper insights?</Title>
              <Paragraph>This summary benchmark is compiled from free sources of data provided by the Australian goverment, if you would like a comprehensive industry benchmark which includes full expense breakdowns, profit per owner/director, business size and location, and more - please call or email us on 02 4304 5929 - team@growthlogic.com.au</Paragraph>
            </Card>
          </Col>
        </Row>
    </div>
  );
}

export default Browse;
