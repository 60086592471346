import React, { useState, useEffect }  from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Typography, Select, Row, Col, Layout, Menu } from 'antd';


import '../App.css';

const { Header, Content, Footer } = Layout;
const { Option } = Select;
const { Title, Paragraph } = Typography;

function ThemeLayout(props) {

  const navigate = useNavigate();

  const onClick = (e) => {
    switch(e.key) {
      case '1':
        navigate("/");
        break;
      default:
        navigate("/Nopage");
    }
  };

  return (
    <Layout className="layout">
      <Header>
        <Row>
          <Col flex="auto">
            <div className="logo">
              <img src="logo-62.png" alt="GrowthLogic Advisors" />
            </div>
          </Col>
          <Col flex="none">
            <Menu
              theme="dark"
              mode="horizontal"
              /*defaultSelectedKeys={['1']}*/
              selectable={false}
              items={[{key: 1, label: 'Home'}]}
              onClick={onClick}
            />          
          </Col>
        </Row>
      </Header>
      <Content> 
        <div className="site-layout-content">
          <Outlet />
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>GrowthLogic Advisors Pty Ltd ©2022</Footer>
    </Layout>
    );
  }
  
  export default ThemeLayout;
  