
import React, { useState, useEffect } from 'react';
import { Button, Radio, InputNumber, Divider, Typography, Select, Row, Col, Layout, Menu, Card, Slider, Segmented  } from 'antd';

import '../App.css';

const { Header, Content, Footer } = Layout;
const { Option } = Select;
const { Title, Paragraph } = Typography;

function NoPage() {

return (
    <div>
    <h2>Nothing to see here!</h2>
    <p>
      
    </p>
  </div>
);
}

export default NoPage;